export const WebsiteFamilyConstants = {

    websites : [
      {  appKey: 'culturequestion', appUrl: 'https://culturequestion.com', name :"Culture Question"},
      // {  appKey: 'icpendulum', appUrl: 'https://icpendulum.com', name :"Intercultural Pendulum"},
      {  appKey: 'culturettt', appUrl: 'https://culturettt.com', name :"Culture TTT"},
      {  appKey: 'ciprofile', appUrl: 'https://ciprofile.com', name :"CI Profile"},
      {  appKey: 'toolbox', appUrl: 'https://interculturaltoolbox.org', name: "Intercultural Toolbox"},
    ]

}