import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class ApplicationService {
  
  appDomain: string;
  appKey: string;

  constructor(
      private router: Router,
      @Inject('appDomain') appDomain: string, // provided by EnvironmentModule in AppModule
      @Inject('appKey') appKey: string // provided by EnvironmentModule in AppModule
  ) {
      this.appDomain = appDomain;
      this.appKey = appKey;
    }


  getSubdomain (){
    let subdomain;
    let hostSplit = window.location.host.split('.'); // ['megacorp','culturettt','com'] or  ['mySubdomain', 'localhost:8004']
    if ((hostSplit.length>2) || (hostSplit.length>1 && hostSplit.find(part=>part.indexOf('localhost')>-1))){
      subdomain = window.location.host.split('.')[0];
    };
    return subdomain ? subdomain : '';
  }
  getAppKey (){
    // NO LONGER IN USE: return process.env['NX_APP_KEY'];
    return this.appKey;
  }
  getAppEnvironmentVariable (key:string){ // for example, 'appDomain'
    return key ? this[key] : null;
  }
  // getPropertyFromRouteConfig (property:string){
    //     return this.router.routerState.root.firstChild.snapshot.data?.[property]; // this must be configured on the route in the routing file, like this: { path: 'login', component: AuthenticationOverviewComponent,  data: { appKey: 'ciprofile'} },
  // }
}
